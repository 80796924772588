/* 
- Name: Raw project - forms.scss
- Version: 1.0
- Latest update: 13.2.2016
- Author: Weblogic Studio
- Author web site: http://weblogic-studio.com
*/

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
FORMS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.form {
  width: 100%;
  margin-top: 100px;
}

input[type='email'],
input[type='number'],
input[type='search'],
input[type='text'],
input[type='tel'],
input[type='url'],
input[type='password'],
textarea,
select {
  @include transitions;
  padding: ($baseline/2);
  width: 100%;
  border: 2px solid #5f6368;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
  &:focus {
    outline: 0;
    border: 2px solid #fff;
  }
}

input[type='email'],
input[type='number'],
input[type='search'],
input[type='text'],
input[type='tel'],
input[type='url'],
input[type='password'],
textarea {
  appearance: none;
}

label,
legend {
  display: block;
  margin-bottom: $baseline;
}

fieldset {
  padding: 0;
  border-width: 0;
  margin-bottom: 50px;
}

textarea {
  height: 188px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $placeholder-color;
}

::-moz-placeholder { /* Firefox 19+ */
  color: $placeholder-color;
  opacity: 1;
}

:-ms-input-placeholder { /* IE 10+ */
  color: $placeholder-color;
}

:-moz-placeholder { /* Firefox 18- */
  color: $placeholder-color;
}

input[type='checkbox'],
input[type='radio'] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  & + label {
    position: relative;
    display: inline-block;
    padding-left: ($baseline*2);
    cursor: pointer;
    &:before, &:after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: $baseline;
      height: $baseline;
      @include transitions;
    }
    &:before {
      content: "";
      border: 1px solid $base;
    }
    &:after {
      transform: scale(0);
    }
  }
  &:disabled {
    & + label {
      color: $disabled-color;
    }
    &:not(:checked), &:checked {
      & + label {
        &:before {
          background-color: $base;
        }
      }
    }
    &:checked {
      & + label {
        &:after {
          color: $tag-colors;
        }
      }
    }
  }
  &:focus {
    & + label {
      &:before {
        outline: 0;
        border: 1px solid $default;
      }
    }
    &:not(:checked), &:checked {
      & + label {
        &:before {
          outline: 0;
          border: 1px solid $default;
        }
      }
    }
  }
}

input[type='checkbox'] {
  & + label {
    &:after {
      content: "\2714";
      color: $default;
      text-align: center;
      line-height: 1.2;
      top: 2px;
    }
  }
  &:checked {
    & + label {
      &:after {
        transform: scale(1);
      }
    }
  }
}

input[type='radio'] {
  & + label {
    &:before {
      border-radius: 100%;
    }
    &:after {
      content: " ";
      background: $default;
      border-radius: 100%;
    }
  }
  &:checked {
    & + label {
      &:before {
        border-color: $base;
      }
      &:after {
        transform: scale(.5);
      }
    }
  }
}