/* 
- Name: Raw project - buttons.scss
- Version: 1.0
- Latest update: 13.2.2016
- Author: Weblogic Studio
- Author web site: http://weblogic-studio.com
*/

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
BUTTONS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.btn,
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  @include transitions;
  display: inline-block;
  overflow: visible;
  margin: ($baseline/2) 0;
  padding: .6em 2em;
  color: white;
  font-size: 16px;
  background-color: #ed1c24;
  text-decoration: none;
  vertical-align: top;
  -webkit-appearance: none;
  outline: none;
  border: 0;
  cursor: pointer;
  zoom: 1;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  i {
    color: #fff;
  }
  &.radius {
    @include border-radius-radius;
  }
  &.round {
    @include border-radius-round;
  }
  &.outline {
    background: none;
    border: 1px solid $default;
    color: $default;
    &:hover,
    &:focus,
    &:active,
    &.active {
      background: none;
      border: 1px solid;
      color: tint($default, 70%);
      border-color: currentColor;
    }
    &.border-primary {
      border: 1px solid $primary;
      color: $primary;
      &:hover,
      &:focus,
      &:active,
      &.active {
        background: $primary;
        color: #fff;
      }
    }

    &.border-secondary {
      border: 1px solid $secondary;
      color: $secondary;
      &:hover,
      &:focus,
      &:active,
      &.active {
        background: $secondary;
        color: #fff;
      }
    }

    &.border-green {
      border: 1px solid $green;
      color: $green;
      &:hover,
      &:focus,
      &:active,
      &.active {
        background: $green;
        color: #fff;
      }
    }

    &.border-yellow {
      border: 1px solid $yellow;
      color: $yellow;
      &:hover,
      &:focus,
      &:active,
      &.active {
        background: $yellow;
        color: #fff;
      }
    }

    &.border-red {
      border: 1px solid $red;
      color: $red;
      &:hover,
      &:focus,
      &:active,
      &.active {
        background: $red;
        color: #fff;
      }
    }

    &.border-blue {
      border: 1px solid $blue;
      color: $blue;
      &:hover,
      &:focus,
      &:active,
      &.active {
        background: $blue;
        color: #fff;
      }
    }

    &.border-silver {
      border: 1px solid $silver;
      color: $silver;
      &:hover,
      &:focus,
      &:active,
      &.active {
        background: $silver;
        color: $base-font-color;
      }
    }

    &.border-gray {
      border: 1px solid $gray;
      color: $gray;
      &:hover,
      &:focus,
      &:active,
      &.active {
        background: $gray;
        color: #fff;
      }
    }

    &.border-white {
      border: 1px solid $white;
      color: $white;
      &:hover,
      &:focus,
      &:active,
      &.active {
        background: $white;
        color: $base-font-color;
      }
    }
  }
  &.large {
    padding: 1em 2.5em;
  }
  &.huge {
    padding: 1.3em 3em;
  }
  &.full-width {
    width: 100%;
    text-align: center;
    padding: 1em .5em;
  }
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #c5161b;
    color: #fff;
    &.bg-primary {
      background-color: tint($primary, 20%);
    }
    &.bg-secondary {
      background-color: tint($secondary, 20%);
    }
    &.success,
    &.bg-green {
      background-color: tint($green, 20%);
    }
    &.bg-yellow {
      background-color: tint($yellow, 20%);
    }
    &.error,
    &.bg-red {
      background-color: tint($red, 20%);
    }
    &.bg-blue {
      background-color: tint($blue, 20%);
    }
    &.bg-silver {
      background-color: tint($silver, 20%);
    }
    &.bg-gray {
      background-color: tint($gray, 20%);
    }
    &.bg-white {
      background-color: tint($white, 20%);
    }
  }
}

input[type='submit'] {
  float: right;
}

.btn-group {
  @include clearfix;
  display: inline-block;
  vertical-align: bottom;
  margin: ($baseline/2) 0;
  padding: 0;
  zoom: 1;
  .btn {
    float: left;
    border-radius: 0;
    margin-left: -1px;
    position: relative;
    &:hover,
    &:focus,
    &:active,
    &.active {
      z-index: 2;
    }
  }
  &.radius {
    .btn {
      &:first-child {
        @include border-radius-custom(3px, 0, 0, 3px);
      }
      &:last-child {
        @include border-radius-custom(0, 3px, 3px, 0);
      }
    }
  }
}