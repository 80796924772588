/* 
- Name: Raw project - variables.scss
- Version: 1.0
- Latest update: 13.2.2016
- Author: Weblogic Studio
- Author web site: http://weblogic-studio.com
*/

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
SCSS VARIABLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* TYPOGRAPHY */

$base-font: (montserrat, sans-serif);
$heading-font: (montserrat, sans-serif);
$base-letter-spacing: .03em;
$base-font-size: 1.4em; /* Equals 14px */
$base-line-height: 1.7;

/* Baseline calculation  */

$baseline: 2.0rem;

/* Font calculations  */

$h1-font-size: 5.0rem;
$h2-font-size: 4.2rem;
$h3-font-size: 3.6rem;
$h4-font-size: 3.0rem;
$h5-font-size: 2.4rem;
$h6-font-size: 1.6rem;
$small-font-size: .8em;

/* PROJECT SPECIFIC COLORS */

$primary: #333;
$secondary: #e9e9e9;
$green: #39c892;
$yellow: #ffc952;
$red: #f1404b;
$blue: #2b90d9;
$silver: #f0f0f0;
$gray: #333;
$white: #fff;

/* BASE COLORS */

$body-background: #131d27;
$base-font-color: #fff;
$heading-font-color: #fff;
$link-color: #fff;
$link-color-hover: #c5161b;
$base: #e1e1e1; /* Basic core element colors like borders, colors, backgrounds etc... */
$default: #333; /* Default backgrounds for UI elements like buttons, badges etc... */
$tag-colors: #777; /* Colors for html tags like em's, italics, abbr's etc... */
$selection-color: #b3d4fc; /* Background of selection */
$disabled-color: #aaa; /* Background of disabled elements */
$placeholder-color: #aaa; /* Color for placeholder text */

/* RESPONSIVE - utilities/mixins */

$screen-xl: 87.438em; /* 1399px */
$screen-lg: 74.938em; /* 1199px */
$screen-md: 59.938em; /* 959px */
$screen-sm: 47.938em; /* 767px */
$screen-xs: 25em; /* 400px */

/* GRID VARIABLES - layout/grid */

$row_wide_screen: 70%; /* Wide screen row width from 1399px and up */
$row_1200-1400: 85%; /* Row width from 1200px to 1399px */
$row_960-1200: 90%; /* Row width from 960px to 1199px */
$row_tablet: 100%; /* Tablet row width from 768px to 960px */
$gutter: 1%; /* Gutter width */
$fw-gutter: 1%; /* Full-width gutter width */

/* SPACER SETTINGS - overrides/spacers */

$normal-spacer: ($baseline * 2);
$double-spacer: ($normal-spacer * 2);