/*
- Name: Raw project - badges.scss
- Version: 1.0
- Latest update: 13.2.2016
- Author: Weblogic Studio
- Author web site: http://weblogic-studio.com
*/

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
BADGES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.badge {
  display: inline-block;
  padding: 5px 9px;
  background: $default;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  font-size: $small-font-size;
  line-height: 1;
  cursor: pointer;
  &.list {
    float: right;
  }
  &.radius {
    @include border-radius-radius;
  }
  &.round {
    @include border-radius-round;
  }
  &.outline {
    border: 1px solid $default;
    background: none;
    color: $default;
  }
}