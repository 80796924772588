/*
- Name: Raw project - media.scss
- Version: 1.0
- Latest update: 13.2.2016
- Author: Weblogic Studio
- Author web site: http://weblogic-studio.com
*/

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
MEDIA
–––––––––––––––––––––––––––––––––––––––––––––––––– */

img {
  position: relative;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  *zoom: 1;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $base;
    border: 1px dashed $default;
  }
  &:after {
    content: attr(alt);
    display: block;
    @include center(vertical);
    width: 100%;
    text-align: center;
  }
  &.align-left,
  .img-wrap.align-left {
    float: left;
    margin: 0 1em ($baseline/2) 0;
  }
  &.align-right,
  .img-wrap.align-right {
    float: right;
    margin: 0 0 ($baseline/2) 1em;
  }
  &.img-left {
    float: left;
    margin: 0 2em $baseline 0;
    border: none;
  }
  &.img-right {
    float: right;
    margin: 0 0 $baseline 2em;
    border: none;
  }
  &.img-responsive {
    width: 100%;
    height: auto;
  }
  &.img-border-thumbnail {
    margin: ($baseline/2);
    padding: 3px;
    border: 1px solid $base;
  }
  &.img-border-thumbnail-2 {
    @include border-radius-radius;
    margin: ($baseline/2);
    padding: 8px;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, .25);
  }
  &.img-border-thumbnail-3 {
    @include border-radius-radius(8px);
    margin: ($baseline/2);
    padding: 4px;
    box-shadow: 0 1px 2px rgba(164, 158, 147, .6);
  }
  &.img-border-line {
    @include border-radius-round;
    margin: ($baseline/2);
    padding: 10px;
    border: 1px solid $base;
  }
  &.img-border-flat {
    margin: ($baseline/2);
    border: 10px solid $base;
  }
  &.img-radius {
    @include border-radius-radius;
  }
  &.img-round {
    @include border-radius-round(100%);
  }
}

.media {
  position: relative;
  overflow: hidden;
  margin: 0 0 $baseline;
  padding-top: $baseline;
  padding-bottom: 51%;
  height: 0;
  border: 1px solid $base;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

figure {
  display: table;
  width: 1px;
}

figcaption {
  margin-top: ($baseline/2);
  font-style: italic;
}