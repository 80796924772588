/* 
- Name: Raw project - breadcrumbs.scss
- Version: 1.0
- Latest update: 13.2.2016
- Author: Weblogic Studio
- Author web site: http://weblogic-studio.com
*/

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
BREADCRUMBS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.breadcrumbs {
  display: block;
  overflow: hidden;
  margin: 0;
  list-style: none;
  li {
    float: left;
    margin: 0;
    padding: 0 .75em 0 0;
    a {
      padding-left: .75em;
      text-transform: uppercase;
    }
    &:before {
      position: relative;
      content: '\00bb';
    }
    &:first-child {
      a {
        padding-left: 0;
      }
      &:before {
        content: ' ';
      }
    }
  }
  .current {
    a {
      padding-left: .75em;
      color: $base-font-color;
      text-transform: uppercase;
      cursor: default;
    }
  }
}