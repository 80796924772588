/* 
- Name: Raw project - custom.scss
- Version: 1.0
- Latest update: 13.2.2016
- Author: Weblogic Studio
- Author web site: http://weblogic-studio.com
*/

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
CUSTOM SCSS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.intro {
  height: 100%;
  width: 100%;
  position: relative;
  background: url(../images/naslovna-fotka.png) no-repeat;
  background-size: 100% 100%;
  padding: $normal-spacer;
  @include breakpoint(xs) {
    padding: 5px;
  }
  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    text-align: center;
    width: 710px;
    @include breakpoint(sm) {
      width: 80%;
    }
    img {
      @include breakpoint(sm) {
        width: 80%;
      }
    }
    h3 {
      text-shadow: 0 0 21px rgba(0, 0, 0, 0.75);
      margin-top: 60px;
      @include breakpoint(sm) {
        font-size: 20px;
      }
    }
  }
  .otkrij {
    width: 120px;
    height: 120px;
    background-color: #ed1c24;
    display: block;
    position: absolute;
    bottom: 13%;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    &:hover {
      background-color: #c5161b;
      color: #fff;
    }
    @include breakpoint(900px) {
      bottom: 10%;
      width: 75px;
      height: 75px;
    }
    @include breakpoint(xs) {
      bottom: 1%;
      width: 75px;
      height: 75px;
    }
    div {
      font-weight: 300;
      font-size: 1.8rem;
      text-transform: uppercase;
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      @include breakpoint(900px) {
        font-size: 1.5rem;
      }
    }
  }
  .kontakt {
    font-weight: 300;
  }
  .language-menu {
    float: right;
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      > li {
        display: inline-block;
        margin: 0;
        padding: 0;
        margin-right: 10px;
        &:last-child {
          margin: 0;
        }
        a {
          font-size: 1.4rem;
          &.active {
            color: #ed1c24;
          }
        }
      }
    }
  }
}

.two {
  position: relative;
  h2 {
    text-transform: uppercase;
  }
  .heading {
    position: relative;
    .kocka1 {
      background: url(../images/kocka1.png) no-repeat;
      width: 430px;
      height: 562px;
      background-size: contain;
      position: absolute;
      z-index: -5;
      top: -200px;
      left: 350px;
      @include breakpoint(815px) {
        display: none;
      }
    }
  }
  .margin {
    margin-top: 100px;
    @include breakpoint(sm) {
      margin-top: 25px;
    }
  }
  .description {
    margin-top: 250px;
    position: relative;
    @include breakpoint(sm) {
      margin-top: 25px;
    }
    .kocka2 {
      background: url(../images/kocka2.png) no-repeat;
      width: 456px;
      height: 456px;
      background-size: contain;
      position: absolute;
      z-index: -5;
      top: -140px;
      left: -140px;
      @include breakpoint(815px) {
        display: none;
      }
    }
  }
}

.three {
  position: relative;
  width: 100%;
  height: 1547px;
  background: url(../images/pozadina-3.png) no-repeat;
  @include breakpoint(sm) {
    height: auto;
    background-position: right;
    &.padding-top-bottom-double {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .row {
      &.padding-top-bottom {
        padding-top: 20px;
      }
    }
  }
  .heading {
    margin-top: 100px;
    @include breakpoint(sm) {
      margin-top: 10px;
    }
  }
  .lista {
    list-style: none;
    margin-top: 100px;
    padding-left: 50px;
    @include breakpoint(sm) {
      padding-left: 0;
      margin-top: 25px;
    }
    li {
      padding: 10px 0;
      margin-bottom: 55px;
      width: 100%;
      border-bottom: 1px solid #ed1c24;
      &:nth-child(odd) {
        width: 90%;
      }
    }
  }
}

.four {
  position: relative;
  .absoluted {
    position: absolute;
    right: 5%;
    @include breakpoint(sm) {
      position: static;
    }
  }
  .heading {
    position: relative;
    text-align: right;
    top: -120px;
    h4 {
      width: 550px;
      @include breakpoint(1165px) {
        width: 450px;
      }
      @include breakpoint(lg) {
        width: 400px;
      }
      @include breakpoint(sm) {
        width: 100%;
      }
    }
    @include breakpoint(sm) {
      top: -30px;
      text-align: center;
    }
    .kocka2 {
      background: url(../images/kocka2.png) no-repeat;
      width: 456px;
      height: 456px;
      background-size: contain;
      position: absolute;
      z-index: -5;
      top: -150px;
      left: 160px;
      @include breakpoint(1165px) {
        width: 406px;
        height: 406px;
        left: 100px;
      }
      @include breakpoint(lg) {
        width: 306px;
        height: 306px;
        top: -100px;
      }
      @include breakpoint(815px) {
        display: none;
      }
    }
  }
  .slika {
    position: relative;
    top: 50px;
    margin-bottom: 100px;
    @include breakpoint(sm) {
      top: 0;
      margin-bottom: 0;
    }
  }
  .description {
    position: relative;
    margin-top: 250px;
    @include breakpoint(sm) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
    .kocka3 {
      background: url(../images/kocka3.png) no-repeat;
      width: 422px;
      height: 437px;
      background-size: contain;
      position: absolute;
      z-index: -5;
      top: -150px;
      left: -150px;
      @include breakpoint(815px) {
        display: none;
      }
    }
  }
  .description2 {
    margin-left: 300px;
    margin-top: 50px;
    @include breakpoint(sm) {
      margin-left: 0;
    }
  }
  .slika2 {
    margin-top: 100px;
    margin-left: 100px;
    @include breakpoint(sm) {
      margin-top: 50px;
      margin-left: 0;
    }
  }
}

.five {
  position: relative;
  width: 100%;
  height: 1525px;
  margin-top: -150px;
  background: url(../images/pozadina-5.png) no-repeat;
  @include breakpoint(sm) {
    height: auto;
  }
  .heading {
    margin-top: 100px;
  }
  .padding-right {
    padding-right: 75px;
  }
  .slika {
    margin-left: 100px;
    @include breakpoint(sm) {
      margin-left: 0;
    }
  }
}

.six {
  position: relative;
  @include breakpoint(sm) {
    padding: 4rem 0;
  }
  .heading {
    position: relative;
    .kocka4 {
      background: url(../images/kocka4.png) no-repeat;
      width: 500px;
      height: 500px;
      background-size: contain;
      position: absolute;
      z-index: -5;
      top: -120px;
      right: -250px;
      @include breakpoint(1035px) {
        display: none;
      }
    }
  }
  .characteristic {
    color: #75828f;
    text-transform: uppercase;
    display: block;
  }
  .characteristic-inline {
    color: #75828f;
    text-transform: uppercase;
    margin-right: 5px;
  }
}

.seven {
  position: relative;
  width: 100%;
  height: 1643px;
  margin-top: -200px;
  z-index: -10;
  background: url(../images/pozadina-7.png) no-repeat;
  @include breakpoint(sm) {
    height: auto;
  }
  .heading {
    margin-top: 50px;
    text-align: right;
    position: relative;
    .kocka5 {
      background: url(../images/kocka5.png) no-repeat;
      width: 336px;
      height: 336px;
      background-size: contain;
      position: absolute;
      z-index: -5;
      top: -200px;
      right: -230px;
      @include breakpoint(1035px) {
        display: none;
      }
    }
  }
  .slika {
    margin-top: 300px;
    @include breakpoint(sm){
      margin-top: 20px;
    }
  }
}

.footer {
  position: relative;
  top: -40px;
  @include breakpoint(sm) {
    position: static;
    padding: 20px 0;
  }
  .text {
    color: #8d9093;
    font-size: 14px;
    a {
      color: inherit;
      &:hover {
        color: $link-color-hover;
      }
    }
  }
  .social {
    padding-bottom: 50px;
    a {
      margin-right: 15px;
    }
  }
}

#nav {
  right: 40px;
  list-style: none;
  margin: 0;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  @include breakpoint(sm) {
    right: 10px;
  }
  li {
    margin-bottom: 15px;
  }
  a {
    background: #ededed;
    display: block;
    font-size: 14px;
    width: 12px;
    height: 12px;
    text-decoration: none;
    text-transform: uppercase;
    @include rotate(45);
    &:hover {
      background: #dedede;
    }
  }
  .current a {
    background: #ed1c24;
  }
}
