/*
- Name: Raw project - base.scss
- Version: 1.0
- Latest update: 4.4.2016
- Author: Weblogic Studio
- Author web site: http://weblogic-studio.com
*/

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
BASE
–––––––––––––––––––––––––––––––––––––––––––––––––– */

html {
  @include box-sizing;
  @include breakpoint(sm) {
    font-size: 55%;
  }
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  min-height: 100%;
  height: 100%;
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  background: $body-background;
  color: $base-font-color;
  letter-spacing: $base-letter-spacing;
  font-size: $base-font-size;
  font-family: $base-font;
  line-height: $base-line-height;
  font-weight: 200;
}

* {
  box-sizing: inherit;
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::-moz-selection {
  background: $selection-color;
  text-shadow: none;
}

::selection {
  background: $selection-color;
  text-shadow: none;
}

::-webkit-selection {
  background: $selection-color;
  text-shadow: none;
}

::-ms-selection {
  background: $selection-color;
  text-shadow: none;
}

input,
select,
textarea,
button {
  &:focus {
    outline: none;
  }
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
LISTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

ul {
  list-style: circle inside;
  &.alt {
    padding-left: 0;
    list-style: none;
    li {
      border-bottom: 1px dotted $base;
    }
  }
}

li,
dt,
dl {
  margin-bottom: ($baseline/2);
}

ol {
  list-style: decimal inside;
}

ol,
ul,
dl {
  margin-top: 0;
  padding-left: 0;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin: $baseline 0 $baseline ($baseline*2);
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
LINKS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

a {
  @include transitions;
  color: $link-color;
  text-decoration: none;
  &:hover {
    color: $link-color-hover;
  }
  &.alt {
    color: $default;
  }
}