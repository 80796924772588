/* 
- Name: Raw project - typography.scss
- Version: 1.0
- Latest update: 13.2.2016
- Author: Weblogic Studio
- Author web site: http://weblogic-studio.com
*/

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
TYPOGRAPHY
–––––––––––––––––––––––––––––––––––––––––––––––––– */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: $baseline;
  padding: 0;
  color: $heading-font-color;
  font-weight: 200;
  font-family: $heading-font;
  a {
    font-weight: inherit;
  }
}

h1, .h1 {
  font-size: $h1-font-size;
  line-height: 1.45;
}

h2, .h2 {
  font-size: $h2-font-size;
  line-height: 1.4;
  @include breakpoint(sm) {
    font-size: 3.6rem;
  }
}

h3, .h3 {
  font-size: $h3-font-size;
  line-height: 1.4;
  font-weight: 200;
}

h4, .h4 {
  font-size: $h4-font-size;
  line-height: 1.5;
}

h5, .h5 {
  font-size: $h5-font-size;
  line-height: 1.5;
}

h6, .h6 {
  font-size: $h6-font-size;
  line-height: $base-line-height;
}

p {
  margin-top: 0;
}

b,
strong {
  font-weight: 600;
}

i,
em {
  color: $tag-colors;
  font-style: italic;
}

u {
  text-decoration: underline;
}

sup,
sub {
  position: relative;
  height: 0;
  vertical-align: baseline;
  font-size: $small-font-size;
  line-height: .5;
  _vertical-align: bottom;
}

sup {
  bottom: 5px;
}

sub {
  top: 5px;
}

small {
  font-size: $small-font-size;
}

abbr[title],
abbr[data-original-title] {
  border-bottom: 1px dotted $tag-colors;
  text-decoration: none;
  cursor: help;
}

code {
  background: #f1f1f1;
  padding: .2em .5em;
  margin: 0 .2rem;
  white-space: nowrap;
}

pre {
  background: #f1f1f1;
  font-family: 'Menlo', 'Consolas', 'Bitstream Vera Sans Mono', 'DejaVu Sans Mono', 'Monaco', monospace;
  & > code {
    background: transparent;
    border-radius: 0;
    display: block;
    padding: 1em;
    white-space: pre;
  }
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
BLOCKQUOTES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

blockquote {
  margin: ($baseline*2) 0;
  padding: ($baseline/2) 2em;
  border-left: solid 4px $base;
  color: $tag-colors;
  font-style: italic;
  p {
    margin: 0;
  }
  .author {
    display: block;
    &:before {
      content: '\2014 \0020';
    }
  }
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
SIZE HELPERS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

i {
  &.x-lg {
    vertical-align: -15%;
    font-size: 1.33333333em;
    line-height: .75em;
  }
  &.x-2 {
    font-size: 2em;
  }
  &.x-3 {
    font-size: 3em;
  }
  &.x-4 {
    font-size: 4em;
  }
  &.x-5 {
    font-size: 5em;
  }
  &.icon-fixed {
    margin-right: 1em;
    width: 1.28571429rem;
    text-align: center;
  }
  &.icon-margin-right {
    margin-right: .3em;
  }
  &.icon-margin-left {
    margin-left: .3em;
  }
}

p {
  &.x-lg {
    font-size: 1.5em;
  }
  &.x-2 {
    font-size: 2em;
  }
  &.x-3 {
    font-size: 3em;
  }
  &.x-4 {
    font-size: 4em;
  }
  &.x-5 {
    font-size: 5em;
  }
}