/* 
- Name: Raw project - pagination.scss
- Version: 1.0
- Latest update: 13.2.2016
- Author: Weblogic Studio
- Author web site: http://weblogic-studio.com
*/

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
PAGINATION
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.pagination {
  margin: $baseline 0;
  list-style: none;
  li {
    @include transitions;
    display: inline-block;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background: $base;
      a {
        color: $base-font-color;
      }
    }
    a {
      display: inline-block;
      padding: .4em .8em;
    }
    &.active {
      background-color: $default;
      a {
        color: #fff;
      }
    }
  }
}