/* 
- Name: Raw project - tables.scss
- Version: 1.0
- Latest update: 13.2.2016
- Author: Weblogic Studio
- Author web site: http://weblogic-studio.com
*/

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
TABLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.table {
  overflow: hidden;
  width: 100%;
  background: none;
  tr {
    border-bottom: 1px solid $base;
  }
  th,
  td {
    margin: $baseline 1em;
    text-align: left;
  }
  th {
    display: none;
  }
  td {
    display: block;
    &:first-child {
      padding-top: ($baseline/2);
    }
    &:last-child {
      padding-bottom: ($baseline/2);
    }
    &:before {
      display: inline-block;
      width: 6.5em;
      content: attr(data-th) ':';
    }
  }
  @include breakpoint(30em, min-width) {
    td {
      &:before {
        display: none;
      }
    }
    th,
    td {
      display: table-cell;
      padding: ($baseline/2);
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}