/* 
- Name: Raw project - fonts.scss
- Version: 1.0
- Latest update: 4.4.2016
- Author: Weblogic Studio
- Author web site: http://weblogic-studio.com
*/

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
FONTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@font-face {
  font-family: 'montserrat';
  src: url('../fonts/montserrat/Montserrat-Light.eot'); /* IE9 Compat Modes */
  src: url('../fonts/montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/montserrat/Montserrat-Light.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/montserrat/Montserrat-Light.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/montserrat/Montserrat-Light.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/montserrat/Montserrat-Light.svg#montserrat') format('svg'); /* Legacy iOS */
  font-weight: 300;
}

@font-face {
  font-family: 'montserrat';
  src: url('../fonts/montserrat/Montserrat-UltraLight.eot'); /* IE9 Compat Modes */
  src: url('../fonts/montserrat/Montserrat-UltraLight.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/montserrat/Montserrat-UltraLight.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/montserrat/Montserrat-UltraLight.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/montserrat/Montserrat-UltraLight.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/montserrat/Montserrat-UltraLight.svg#montserrat') format('svg'); /* Legacy iOS */
  font-weight: 200;
}

@font-face {
  font-family: 'montserrat';
  src: url('../fonts/montserrat/Montserrat-SemiBold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/montserrat/Montserrat-SemiBold.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/montserrat/Montserrat-SemiBold.svg#montserrat') format('svg'); /* Legacy iOS */
  font-weight: 600;
}
